import React from "react"

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    aria-labelledby="menuIconLabel"
    role="img"
  >
    <circle cx="28" cy="28" r="24" fill="#fff" fillOpacity="0.2" />
    <path
      d="M16 36h24v-2.667H16V36zm0-6.667h24v-2.666H16v2.666zM16 20v2.667h24V20H16z"
      fill="#fff"
    />
  </svg>
)

export default MenuIcon
