import React, { forwardRef, useState, useEffect, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import SearchFieldIcon from "../icons/SearchFieldIcon"
import styles from "./styles.module.css"
import * as JsSearch from "js-search"
import sanitizeHtml from "sanitize-html"
import { Link } from "gatsby"
import Img from "gatsby-image"

const HeaderSearch = forwardRef((props, ref) => {
  const searchInputRef = useRef()
  const { dataset, hideSearch } = props

  const [searchState, setSearchState] = useState({
    isLoading: true,
    search: null,
    isError: false,
  })

  const [searchResponse, setSearchResponse] = useState({
    searchQuery: "",
    searchResults: [],
  })

  const resetSearch = () => {
    setSearchResponse({
      searchQuery: "",
      searchResults: [],
    })
  }

  function keyDownHandler(event) {
    if (event.keyCode === 27) {
      hideSearch()
      resetSearch()
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler)
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus()
    }
    rebuildIndex()
    return () => {
      window.removeEventListener("keydown", keyDownHandler)
    }
  }, [])

  const rebuildIndex = () => {

    const dataToSearch = new JsSearch.Search("id")
    dataToSearch.addIndex("title")
    dataToSearch.addIndex("isbn")
    dataToSearch.addIndex("creators")

    dataToSearch.addDocuments(dataset)

    setSearchState({ search: dataToSearch, isLoading: false })
  }

  const onSearchChange = e => {
    const { search } = searchState
    const queryResult = search.search(e.target.value)

    setSearchResponse({
      searchQuery: e.target.value,
      searchResults: queryResult,
    })
  }

  return (
    <div className={styles.searchContainer} ref={ref}>
      <div className={styles.search}>
        <div className={styles.searchInputContainer}>
          <div
            className={styles.innerSearch}
            style={{
              position: `relative`,
              margin: `0.875rem 24.4205298013245%`,
            }}
          >
            <input
              ref={searchInputRef}
              type="search"
              id="search"
              name="search"
              value={searchResponse.searchQuery}
              aria-label={"Sök"}
              placeholder={"Sök"}
              autoComplete={"off"}
              style={{
                width: `100%`,
                height: `3.5rem`,
                padding: `0.875rem`,
                border: `none`,
                background: `rgba(255, 255, 255, 0.75)`,
                borderRadius: 3,
                fontSize: `1.125rem`,
                textAlign: `center`,
              }}
              className={styles.input}
              onChange={onSearchChange}
            />
            <button
              type="submit"
              className={`${styles.button}`}
              style={{
                position: `absolute`,
                top: 0,
                left: 0,
                width: `3.5rem`,
                height: `3.5rem`,
                padding: 0,
              }}
            >
              <SearchFieldIcon />
            </button>
          </div>
        </div>
        {searchResponse.searchQuery ? (
          <div className={styles.searchResults}>
            <ul className={styles.searchResultsList}>
              {searchResponse.searchResults.slice(0, 20).map(item => {
                if (item.type === "book") {
                  return (
                    <li className={styles.resultListItem} key={item.id}>
                      <Link to={`/bocker/${item.slug}`}>
                        {item.image && item.image.localFile ? (
                          <Img
                            className={styles.image}
                            src={item.image.localFile.childImageSharp.src}
                            fixed={item.image.localFile.childImageSharp.fixed}
                            alt={item.image.altText}
                          />
                        ) : null}
                        <h4
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(item.title.replace(/\u2028/g, " ")),
                          }}
                        />
                        <div className={styles.bookCreators}>
                          {item.creators.map((c, cIndex) =>
                            cIndex === item.creators.length - 1 ? <span>{c}</span> : <span>{`${c}, `}</span>
                          )}
                        </div>
                      </Link>
                    </li>
                  )
                }
                if (item.type === "creator") {
                  return (
                    <li className={styles.resultListItem} key={item.id}>
                      <Link to={`/upphovspersoner/${item.slug}`}>
                        {item.image && item.image.localFile ? (
                          <Img
                            className={styles.image}
                            src={item.image.localFile.childImageSharp.src}
                            fixed={item.image.localFile.childImageSharp.fixed}
                            alt={item.image.altText}
                          />
                        ) : null}
                        <h4
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(item.title.replace(/\u2028/g, " ")),
                          }}
                        />
                      </Link>
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  )
})

HeaderSearch.displayName = `HeaderSearch`

export default HeaderSearch
