/**
 * Layout component that queries for data with useSiteMetadata helper hook
 *
 * See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import useSiteMetadata from "../../helpers/useSiteMetadata"

import Header from "../Header"
import Footer from "../Footer"
import "normalize.css"
import "./layout.css"

const Layout = ({ children }) => {
  const { title } = useSiteMetadata()

  return (
    <div style={{ color: `#00a9c5` }}>
      <Header siteTitle={title} />
      <main>{children}</main>
      <Footer siteTitle={title} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
