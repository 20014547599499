import { Link, StaticQuery, graphql } from "gatsby"
import React, { useState, useCallback, useRef } from "react"
import Helmet from "react-helmet"

import HeaderLogo from "../logos/HeaderLogo"
import LanguageIcon from "../icons/LanguageIcon"
import SearchIcon from "../icons/SearchIcon"
import MenuIcon from "../icons/MenuIcon"
import CloseMenuIcon from "../icons/CloseMenuIcon"
import HeaderSearch from "../HeaderSearch"
import { useStore } from "../../store/useStore"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"

import * as inlineStyles from "../Layout/inlineStyles"
import styles from "./styles.module.css"

const Header = () => {
  const [navToggle, setNavToggle] = useState(false)
  const [searchToggle, setSearchToggle] = useState(false)

  const { dispatch } = useStore()

  const resetBooksList = useCallback(() => {
    dispatch({ type: "resetBooksList" })
  }, [dispatch])

  const resetCreatorsList = useCallback(() => {
    dispatch({ type: "resetCreatorsList" })
  }, [dispatch])

  const handleNavClick = () => {
    setNavToggle(false)
  }

  const handleBookClick = () => {
    handleNavClick()
    resetBooksList()
  }

  const handleCreatorsClick = () => {
    handleNavClick()
    resetCreatorsList()
  }

  const handleNavButtonClick = event => {
    event.currentTarget.blur()
    setSearchToggle(false)
    setNavToggle(!navToggle)
  }

  const handleSearchClick = event => {
    event.currentTarget.blur()
    setSearchToggle(!searchToggle)
    setNavToggle(false)
  }

  // Create a ref that we add to the element for which we want to detect outside clicks
  const searchRef = useRef()
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(searchRef, () => setSearchToggle(false))

  return (
    <StaticQuery
      query={graphql`
        query siteSearchQuery {
          allWordpressWpBooks(
            sort: { order: DESC, fields: acf___editions___release_date }
            limit: 1000
          ) {
            edges {
              node {
                id
                title
                slug
                acf {
                  isbn
                  creators {
                    creator {
                      title: post_title
                      acf {
                        firstName: first_name
                        lastName: last_name
                      }
                    }
                  }
                  cover {
                    altText: alt_text
                    caption
                    localFile {
                      childImageSharp {
                        fixed(height: 64) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          allWordpressWpCreators(
            sort: { fields: [acf___last_name, acf___first_name], order: ASC }
          ) {
            edges {
              node {
                id
                title
                slug
                creatorRoles: creator_roles
                acf {
                  firstName: first_name
                  lastName: last_name
                  portraitImage: portrait_image {
                    altText: alt_text
                  }
                  featuredPortraitImage: featured_portrait_image {
                    altText: alt_text
                    caption
                    localFile {
                      childImageSharp {
                        fixed(height: 64) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const allWordpressWpBooks = data.allWordpressWpBooks.edges.map(
          bookItem => bookItem.node
        )
        const allWordpressWpCreators = data.allWordpressWpCreators.edges.map(
          creatorItem => creatorItem.node
        )

        const bookDataset = allWordpressWpBooks.map(b => {
          return {
            id: b.id,
            type: "book",
            title: b.title.replace(/&#8211;/g, "–"),
            slug: b.slug,
            isbn: b.acf.isbn,
            creators: b.acf.creators.map(
              c => (c.creator && c.creator.title) || ""
            ),
            image: b.acf.cover,
          }
        })

        const creatorDataset = allWordpressWpCreators.map(c => {
          return {
            id: c.id,
            type: "creator",
            title: c.title,
            slug: c.slug,
            image: c.acf.featuredPortraitImage,
          }
        })

        const dataset = [...bookDataset, ...creatorDataset]

        return (
          <>
            <header
              className={`header ${styles.header}`}
              style={{
                background: `#00a9c5`,
                minHeight: 128,
              }}
            >
              <Helmet>
                <body className={navToggle ? "no-scroll" : null} />
              </Helmet>
              <div
                className="header-container"
                style={{
                  ...inlineStyles.headerContainer,
                }}
              >
                <nav>
                  <ul
                    className={`nav-list ${styles.navList}`}
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      alignItems: `center`,
                      listStyleType: `none`,
                      margin: `0`,
                      padding: `0`,
                      minHeight: 128,
                    }}
                  >
                    <li className={`logo ${styles.logo}`} style={{ zIndex: 100 }}>
                      <Link to="/" onClick={handleNavClick}>
                        <HeaderLogo />
                      </Link>
                    </li>
                    <li
                      style={{
                        width: `100%`,
                      }}
                    >
                      <ul
                        className={`${styles.navListItems} ${
                          navToggle ? styles.active : ""
                        }`}
                        style={{
                          display: `flex`,
                          flexDirection: `row`,
                          alignItems: `center`,
                          listStyleType: `none`,
                          margin: `0`,
                          padding: `0`,
                          minHeight: 128,
                        }}
                      >
                        <li
                          className={`${styles.navListItem} ${styles.home}`}
                          style={{ display: `none` }}
                        >
                          <Link
                            to="/"
                            activeClassName={styles.active}
                            onClick={handleNavClick}
                          >
                            <span
                              style={{
                                position: `relative`,
                              }}
                            >
                              Hem
                            </span>
                          </Link>
                        </li>
                        <li
                          className={styles.navListItem}
                          style={{ marginLeft: `auto` }}
                        >
                          <Link
                            to="/bocker"
                            activeClassName={styles.active}
                            style={{
                              color: `#fff`,
                              textDecoration: `none`,
                              padding: `1rem 0`,
                            }}
                            onClick={handleBookClick}
                          >
                            <span
                              style={{
                                position: `relative`,
                                padding: `0.25rem 0.75rem`,
                                zIndex: 1,
                              }}
                            >
                              Böcker
                            </span>
                          </Link>
                        </li>
                        <li className={styles.navListItem}>
                          <Link
                            to="/upphovspersoner"
                            activeClassName={styles.active}
                            style={{
                              color: `#fff`,
                              textDecoration: `none`,
                              padding: `1rem 0`,
                            }}
                            onClick={handleCreatorsClick}
                          >
                            <span
                              style={{
                                position: `relative`,
                                padding: `0.25rem 0.75rem`,
                                zIndex: 1,
                              }}
                            >
                              Upphovspersoner
                            </span>
                          </Link>
                        </li>
                        <li className={styles.navListItem}>
                          <Link
                            to="/om-oss"
                            activeClassName={styles.active}
                            style={{
                              color: `#fff`,
                              textDecoration: `none`,
                              padding: `1rem 0`,
                            }}
                            onClick={handleNavClick}
                          >
                            <span
                              style={{
                                position: `relative`,
                                padding: `0.25rem 0.75rem`,
                                zIndex: 1,
                              }}
                            >
                              Om oss
                            </span>
                          </Link>
                        </li>
                        <li className={styles.navListItem}>
                          <Link
                            to="/manus"
                            activeClassName={styles.active}
                            style={{
                              color: `#fff`,
                              textDecoration: `none`,
                              padding: `1rem 0`,
                            }}
                            onClick={handleNavClick}
                          >
                            <span
                              style={{
                                position: `relative`,
                                padding: `0.25rem 0.75rem`,
                                zIndex: 1,
                              }}
                            >
                              Manus
                            </span>
                          </Link>
                        </li>
                        <li className={styles.navListItem}>
                          <a
                            href="https://press.opal.se/"
                            style={{
                              color: `#fff`,
                              textDecoration: `none`,
                              padding: `1rem 0`,
                            }}
                          >
                            <span
                              style={{
                                position: `relative`,
                                padding: `0.25rem 0.75rem`,
                                zIndex: 1,
                              }}
                            >
                              Press
                            </span>
                          </a>
                        </li>
                        <li className={styles.navListItem}>
                          <Link
                            to="/foreign-rights"
                            activeClassName={styles.active}
                            style={{
                              color: `#fff`,
                              textDecoration: `none`,
                              padding: `1rem 0`,
                            }}
                            onClick={handleNavClick}
                          >
                            <span
                              style={{
                                position: `relative`,
                                padding: `0.25rem 0.75rem`,
                                zIndex: 1,
                              }}
                            >
                              Foreign Rights
                            </span>
                          </Link>
                        </li>
                        <li className={styles.navListItem}>
                          <Link
                            to="/kontakt"
                            activeClassName={styles.active}
                            style={{
                              color: `#fff`,
                              textDecoration: `none`,
                              padding: `1rem 0`,
                            }}
                            onClick={handleNavClick}
                          >
                            <span
                              style={{
                                position: `relative`,
                                padding: `0.25rem 0.75rem`,
                                zIndex: 1,
                              }}
                            >
                              Kontakt
                            </span>
                          </Link>
                        </li>
                        <li
                          className={`${styles.navListItem} ${styles.languageContainer}`}
                          style={{
                            marginLeft: `auto`,
                            textAlign: `center`,
                          }}
                        >
                          <Link
                            to="/in-english"
                            className={styles.language}
                            activeClassName={styles.active}
                            style={{
                              textDecoration: `none`,
                            }}
                            onClick={handleNavClick}
                          >
                            <div className={styles.iconContainer}>
                              <LanguageIcon />
                            </div>
                            <span
                              id="languageIconLabel"
                              className={styles.label}
                              style={{
                                display: `block`,
                                color: `#abe3ec`,
                                fontSize: `0.625rem`,
                                fontStyle: `italic`,
                              }}
                            >
                              In English
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={styles.searchContainer}
                      style={{
                        paddingLeft: `8px`,
                        textAlign: `center`,
                      }}
                    >
                      <button
                        type="button"
                        className={styles.search}
                        onClick={handleSearchClick}
                        style={{
                          background: `none`,
                          border: `none`,
                        }}
                      >
                        <div className={styles.iconContainer}>
                          {searchToggle ? <CloseMenuIcon /> : <SearchIcon />}
                        </div>
                        <span
                          id="searchIconLabel"
                          className={styles.label}
                          style={{
                            display: `block`,
                            color: `#abe3ec`,
                            fontSize: `0.625rem`,
                            fontStyle: `italic`,
                            textDecoration: `none`,
                          }}
                        >
                          Sök
                        </span>
                      </button>
                    </li>
                    <li
                      className={`${styles.navButtonContainer}`}
                      style={{ display: `none` }}
                    >
                      <button
                        type="button"
                        className={`${styles.navButton}`}
                        onClick={handleNavButtonClick}
                      >
                        <div className={styles.iconContainer}>
                          {navToggle ? (
                            <>
                              <CloseMenuIcon />
                              <span id="closeMenuIconLabel" className={styles.label}>
                                Stäng
                              </span>
                            </>
                          ) : (
                            <>
                              <MenuIcon />
                              <span id="menuIconLabel" className={styles.label}>
                                Meny
                              </span>
                            </>
                          )}
                        </div>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>
            {searchToggle ? (
              <HeaderSearch
                dataset={dataset}
                ref={searchRef}
                hideSearch={() => setSearchToggle(false)}
              />
            ) : null}
          </>
        )
      }}
    />
  )
}

export default Header
