import React from "react"

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    aria-labelledby="searchIconLabel"
    role="img"
  >
    <circle cx="28" cy="28" r="24" fill="#fff" fillOpacity="0.2" />
    <path
      d="M32.083 30.333h-.921l-.327-.315a7.55 7.55 0 0 0 1.832-4.935 7.583 7.583 0 1 0-15.167 0 7.583 7.583 0 0 0 7.583 7.584 7.55 7.55 0 0 0 4.935-1.832l.315.327v.921l5.834 5.822 1.738-1.738-5.822-5.834zm-7 0a5.243 5.243 0 0 1-5.25-5.25 5.243 5.243 0 0 1 5.25-5.25 5.243 5.243 0 0 1 5.25 5.25 5.243 5.243 0 0 1-5.25 5.25z"
      fill="#fff"
    />
  </svg>
)

export default SearchIcon
