import React from "react"

const CloseMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    aria-labelledby="closeMenuIconLabel"
    role="img"
  >
    <circle cx="28" cy="28" r="24" fill="#fff" fillOpacity="0.2" />
    <path
      d="M28 26.586l5.657-5.657 1.414 1.414L29.414 28l5.657 5.657-1.414 1.414L28 29.414l-5.657 5.657-1.414-1.414L26.586 28l-5.657-5.657 1.414-1.414L28 26.586z"
      fill="#fff"
    />
  </svg>
)

export default CloseMenuIcon
