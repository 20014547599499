import { Link } from "gatsby"
import React from "react"

import FooterLogo from "../logos/FooterLogo"
import InstagramFeed from "../InstagramFeed"
import * as inlineStyles from "../Layout/inlineStyles"
import styles from "./styles.module.css"

const Footer = () => (
  <footer>
    <InstagramFeed />
    <div className="container" style={inlineStyles.container}>
      <nav>
        <ul className={styles.navList}>
          <li className={styles.logo}>
            <Link to="/">
              <FooterLogo />
            </Link>
          </li>
          <li className={styles.navListItemsContainer}>
            <ul className={styles.navListItems}>
              <li className={styles.navListItem}>
                <Link to="/">Hem</Link>
              </li>
              <li className={styles.navListItem}>
                <Link to="/bocker">Böcker</Link>
              </li>
              <li className={styles.navListItem}>
                <Link to="/upphovspersoner">Upphovspersoner</Link>
              </li>
              <li className={`${styles.navListItem} ${styles.pushFollowing}`}>
                <Link to="/manus">Manus</Link>
              </li>
              <li className={styles.navListItem}>
                <Link to="/press">Press</Link>
              </li>
              <li className={styles.navListItem}>
                <Link to="/foreign-rights">Foreign Rights</Link>
              </li>
              <li className={styles.navListItem}>
                <Link to="/kontakt">Kontakt</Link>
              </li>
              <li className={styles.navListItem}>
                <Link to="/in-english">In English</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
)

export default Footer
