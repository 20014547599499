import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import * as inlineStyles from "../Layout/inlineStyles"
import styles from "./styles.module.css"

const InstagramFeed = () => (
  <StaticQuery
    query={graphql`
      query InstaQuery {
        allInstaNode(sort: { order: DESC, fields: timestamp }, limit: 6) {
          edges {
            node {
              id
              timestamp
              localFile {
                childImageSharp {
                  fluid(maxWidth: 384, maxHeight: 384) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const instagramPosts = data.allInstaNode.edges
        .map(instagramItem => instagramItem.node)
        .slice(0, 6)

      if (instagramPosts && instagramPosts.length) {
        return (
          <div className={styles.instagramFeed}>
            <div className="container" style={inlineStyles.container}>
              <a
                href="https://www.instagram.com/bokforlagetopal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3 className={styles.title}>Följ oss på Instagram</h3>
              </a>
              <ul className={styles.instagramList}>
                {instagramPosts.map(item => {
                  return (
                    <li key={item.id}>
                      <a
                        href={`https://www.instagram.com/p/${item.id}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img
                          src={item.localFile.childImageSharp.fluid.src}
                          fluid={item.localFile.childImageSharp.fluid}
                          alt={item.caption}
                        />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )
      }
      return null
    }}
  />
)

export default InstagramFeed
