/**
 * Hook that queries for data with Gatsby's useStaticQuery hook
 *
 * See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/
 */

import { useStaticQuery, graphql } from "gatsby"

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
