import React from "react"

const LanguageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    aria-labelledby="languageIconLabel"
    role="img"
  >
    <circle cx="28" cy="28" r="18" fill="#fff" fillOpacity="0.2" />
    <path
      d="M27.99 18C22.47 18 18 22.48 18 28s4.47 10 9.99 10C33.52 38 38 33.52 38 28s-4.48-10-10.01-10zm6.93 6h-2.95a15.65 15.65 0 0 0-1.38-3.56A8.03 8.03 0 0 1 34.92 24zM28 20.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM20.26 30c-.16-.64-.26-1.31-.26-2s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2h-3.38zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0 1 21.08 32zm2.95-8h-2.95a7.987 7.987 0 0 1 4.33-3.56A15.65 15.65 0 0 0 24.03 24zM28 35.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM30.34 30h-4.68c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56zM32.36 30c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
      fill="#fff"
    />
  </svg>
)

export default LanguageIcon
