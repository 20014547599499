import React from "react"

const SearchFieldIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    aria-labelledby="searchFieldIconTitle"
    role="img"
  >
    <title id="searchFieldIconTitle">Sök</title>
    <path
      d="M31.864 30.321h-.812l-.289-.278a6.659 6.659 0 0 0 1.616-4.354C32.379 22.02 29.36 19 25.69 19S19 22.02 19 25.689v.001c0 3.67 3.02 6.69 6.689 6.69a6.664 6.664 0 0 0 4.354-1.616l.277.288v.812L35.467 37 37 35.467l-5.136-5.146zm-6.175 0h-.006c-2.537 0-4.625-2.088-4.625-4.625v-.007-.006c0-2.537 2.088-4.625 4.625-4.625h.012c2.538 0 4.625 2.088 4.625 4.625V25.696c0 2.537-2.087 4.625-4.625 4.625h-.006z"
      fill="#00a9c5"
    />
  </svg>
)

export default SearchFieldIcon
