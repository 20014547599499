export const container = {
  margin: `0 auto`,
  maxWidth: `79rem` /* 1264 px */,
  padding: `1rem 1.75rem`,
}

export const singleItemContainer = {
  ...container,
  maxWidth: `46.25rem` /* 740 px */,
  fontSize: `1.125rem` /* 18 px */,
  // fontSize: `1.25rem` /* 20 px */,
  lineHeight: `1.75`,
  marginBottom: `1.75rem`,
}

export const headerContainer = {
  ...container,
  padding: `0 1.75rem`,
}

export const bookContainer = {
  ...container,
  fontSize: `1.125rem` /* 18 px */,
  lineHeight: `1.75`,
  display: `grid`,
  gridTemplateColumns: `1fr 1fr`,
  gridGap: `1.75rem`,
  marginBottom: `1.75rem`,
}

export const creatorContainer = {
  ...container,
  fontSize: `1.125rem` /* 18 px */,
  lineHeight: `1.75`,
  display: `grid`,
  gridTemplateColumns: `1fr 1fr`,
  gridGap: `1.75rem`,
  marginBottom: `1.75rem`,
}
